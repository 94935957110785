const navigation = [
  { name: "Dashboard", href: "/app/dashboard", current: false },
  { name: "Usecases", href: "/app/usecases", current: false },
  { name: "API Keys", href: "/app/api-keys", current: false },
  { name: "Account", href: "/app/profile", current: false },
];

export const HOME = "/"
export const LOGIN = "/login";
export const SIGNUP = "/signup";
export const FORGOT_PASSWORD = "/forgot-password";

export const APP_HOME = "/app"
export const DASHBOARD = APP_HOME + "/dashboard";
export const USECASES = APP_HOME + "/usecases";
export const API_KEYS = APP_HOME + "/api-keys";
export const PROFILE = APP_HOME + "/profile";

export default navigation;
