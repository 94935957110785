import {  useState } from "react";
import useCasesServices from "../../services/useCasesServices";
import {LoadingSpinner} from "../Commons";

export default function SlideOverDetails({ data }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isEnabled, setIsEnabled] = useState(data.is_enabled);

  const updateUseCaseStatus = async (status) => {
    setIsLoading(true);
    const response = await useCasesServices.updateUseCaseStatus(data.id, status);
    if (response.data) {
      setIsEnabled(status);
    }
    setIsLoading(false);
  };

  return (
    <div className="w-full">
      <div>
        <div className="text-base font-medium text-gray-900">
          <p>Description</p>
        </div>

        <div className="mt-2 text-sm text-gray-600">
          <p>{data.description}</p>
        </div>
      </div>

      <div className="mt-4">
        <div className="text-base font-medium text-gray-900">
          <p>How to use</p>
        </div>

        <div className="mt-2 text-sm text-gray-600">
          <p>
            Attach documentation link here.
          </p>
        </div>
      </div>

      <div className="border-t border-gray-200 py-2 px-4 sm:px-6 mt-8">
        <div className="mt-6">
          {isEnabled ? (
            <div
              onClick={()=>updateUseCaseStatus(false)}
              className="flex items-center justify-center cursor-pointer rounded-md border border-transparent bg-gray-700 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-gray-500"
            >
              {isLoading? <LoadingSpinner/>: "Disable"}
            </div>
          ) : (
            <div
              onClick={()=>updateUseCaseStatus(true)}
              className="flex items-center justify-center cursor-pointer rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700"
            >
              {isLoading? <LoadingSpinner/>: "Enable"}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
