import React, { useState } from "react";
import {SlideOver,IndicatorBadge} from "../Commons";
import {SlideOverDetails} from "./";

function UseCaseCard({data, slideOverCloseHandler}) {
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked(true);
  };

  const handleClose = () => {
    setClicked(false);
    slideOverCloseHandler();
  };

  return (
    <div
      className="block p-6 h-48 md:w-96 sm:w-60 rounded-md shadow-md bg-white cursor-pointer"
      onClick={handleClick}
    >
      <h5 className="text-gray-900 leading-tight font-medium mb-2">{data.name}</h5>
      <p className="text-gray-700 font-light text-sm mb-4">{data.description}</p>
      <div className="flex justify-end bottom-2 right-1 w-full p-1">
        {<IndicatorBadge isActive={data.is_enabled} />}
      </div>
      {
        <SlideOver
          title={data.name}
          show={clicked}
          closeFn={handleClose}
          children={<SlideOverDetails data={data} />}
        />
      }
    </div>
  );
}

export default UseCaseCard;
