import { useEffect, useState } from "react";
import apiKeyService from '../../services/apiKeyService';
import {ConfirmPopup} from "../../components/Commons";
import {EditAPIKey,CreateAPIKey,KeyListIndividual} from '../../components/ApiKeys';
import {TableSkeleton,ButtonSkeleton} from "../../components/Skeletons";


export default  function APIKeys() {
  const [apiKeys, setApiKeys] = useState([]);
  const [showCreate, setShowCreate] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [deleteKeyId, setDeleteKeyId] = useState("");
  const [editKeyId, setEditKeyId] = useState("");
  const [refreshAPIKeys, setRefreshAPIKeys] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function refreshList() {
    setRefreshAPIKeys(!refreshAPIKeys);
  }

  const openDeleteModal = (key_id) => {
    setDeleteKeyId(key_id);
    setShowDelete(true);
  };


  /**
   * Delete API key and refresh the list is the response is true 
   * else hide the delete confirmation popup. 
   * @param {boolean} response
   * @returns {void}
   *  */
  const deleteConfirmationHandler = async (response) => {

    // On Confirm
    if (response) {
      await apiKeyService.deleteKey(deleteKeyId);
      refreshList();
    }
    setDeleteKeyId("");
    setShowDelete(false);
  };

  const openEditKeyModal = (id) => {
    setEditKeyId(id);
    setShowEdit(true);
  };


  /** 
   * Edit API key and refresh if success.
   * @param {boolean} response
  */
  const editModalHandler = async (data) => {
    if (data) {
      refreshList();
    }
    setShowEdit(false);
    setEditKeyId("");
  };

  const openCreateKeyModal = () => {
    setShowCreate(true);
  };

  /**
   * Create API key and refresh if success.
   * @param {boolean} response
   */
  const createModalHandler = (response) => {
    if (response) {
      refreshList();
    }
    setShowCreate(false);
  };

  useEffect(() => {
    async function getAPIKeys() {
      setIsLoading(true);
      let response = await apiKeyService.getKeys();
      setApiKeys(response.data.results);
      setIsLoading(false);
    }
    getAPIKeys();
  }, [refreshAPIKeys]);

  return (
    <div>
      <div className="flex flex-col max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 min-h-[calc(100vh-4rem-84px)] space-y-5">
        <div className="text-right">
          {apiKeys.length > 0 && !isLoading && (
            <button
              onClick={openCreateKeyModal}
              className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded"
              data-modal-toggle="authentication-modal"
            >
              Create API key
            </button>
          )}
          <ButtonSkeleton show={isLoading}/>
        </div>
        <div>
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg w-full">
            <TableSkeleton rowCount={5} colCount={5} show={isLoading} />
            <table className={`${isLoading ? 'hidden' : ''} w-full text-sm text-left text-gray-500 dark:text-gray-400`}>
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-4">Name</th>
                  <th scope="col" className="px-6 py-4">Key</th>
                  <th scope="col" className="px-6 py-4">Last Modified</th>
                  <th scope="col" className="px-6 py-4">Status</th>
                  <th scope="col" className="px-6 py-4">Actions</th>
                </tr>
              </thead>
              <tbody className={`${isLoading ? 'hidden' : ''}`}>
                {apiKeys.map((item) => (
                  <KeyListIndividual
                    key={item.key}
                    title={item.name}
                    apikey={item.key}
                    isActive={item.is_enabled}
                    id={item.id}
                    modified={item.updated}
                    deleteKeyFn={openDeleteModal}
                    editKeyFn={openEditKeyModal}
                  />
                ))}
                {apiKeys.length === 0 && (
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <td className="px-6 py-4" colSpan={5}>
                      <div className="flex flex-col justify-center items-center h-80 gap-y-2">
                        <h4 className="font-semibold text-xl">No API keys</h4>
                        <p> Create new key to use AI vision services.</p>
                        <button
                          onClick={openCreateKeyModal}
                          className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded"
                          data-modal-toggle="authentication-modal"
                        >
                          Create API Key
                        </button>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

          </div>
        </div>
        <div>
          <ConfirmPopup
            show={showDelete}
            confirmHandler={deleteConfirmationHandler}
            title={"Confirm Delete"}
            body={
              "Are you sure you want to delete API key? This action cannot be undone."
            }
          />
          <CreateAPIKey
            show={showCreate}
            createHandler={createModalHandler}
          />
          <EditAPIKey
            id={editKeyId}
            show={showEdit}
            editHandler={editModalHandler}
          />
        </div>
      </div>
    </div>

  );
}
