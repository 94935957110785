import { LockClosedIcon } from "@heroicons/react/solid";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import GoogleLogin from "react-google-login";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import authServices from "../../services/authServices";
import userServices from "../../services/userServices";
import LoadingSpinner from "../../components/Commons/LoadingSpinner";
import { DASHBOARD, FORGOT_PASSWORD } from "../../constants/appNavigations";

function SignIn() {
  const [loading, setLoading] = useState(false);
  const [checkedRememberMe, setCheckedRememberMe] = useState(false);
  const [preFillData, setPreFillData] = useState({ email: "", password: "" });
  const [formError, setFormError] = useState("");

  const navigate = useNavigate();
  const rememberMeInfo = userServices.checkRememberMe();


  /**
  * Setup remember me info according checkbox
  * @param {email, password} values 
  */
  const rememberMeSetup = (values) => {
    if (checkedRememberMe) {
      userServices.setRememberMe(values.email);
    } else {
      userServices.clearRememberMe();
    }
  }

  /**
   * Login user if success redirect to App Home page
   * @param {email, password} values
   * */
  const singInAPIHandler = async (values) => {
    setLoading(true);
    let { data, error } = await authServices.userLogin(values);

    if (data) {
      userServices.setUser(data);
      rememberMeSetup(values);
      navigate(DASHBOARD);
    }

    if (error) {
      setFormError(error.message);
      setLoading(false);
    }
  }

  const formik = useFormik({
    initialValues: preFillData,
    enableReinitialize: true,
    validationSchema: Yup.object({
      email: Yup.string().email("Enter valid email").required("Required"),
      password: Yup.string()
        .max(15, "Must be 15 characters or less")
        .min(6, "Need at least 6 characters")
        .required("Required"),
    }),
    onSubmit: async (values) => singInAPIHandler(values)
  });

  useEffect(() => {
    const user = userServices.getUser();
    if (user) {
      navigate(DASHBOARD);
    }
    if (rememberMeInfo) {
      setPreFillData({ email: rememberMeInfo, password: '' });
    }
  }, [navigate, rememberMeInfo]);

  return (
    <>
      <div className="min-h-screen flex justify-center items-center">
        <form
          className="w-full max-w-md my-10 px-5"
          onSubmit={formik.handleSubmit}
        >
          <div>
            <h1 className="text-center text-3xl font-extrabold">ARJUN</h1>
            <h2 className="mt-4 mb-5 text-center text-3xl font-extrabold text-gray-900">
              Sign in to your account
            </h2>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-email"
              >
                Email
              </label>
              <input
                className="appearance-none block w-full placeholder:text-sm text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-email"
                type="email"
                placeholder="Email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.email && <p className="text-red-500 text-xs mt-3">
                {formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : ""}
              </p>}
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Password
              </label>
              <input
                className="appearance-none block w-full placeholder:text-sm text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-password"
                type="password"
                placeholder="Password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.password && <p className="text-red-500 text-xs mt-3">
                {formik.touched.password && formik.errors.password
                  ? formik.errors.password
                  : ""}
              </p>}
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input
                id="remember-me"
                name="remember-me"
                type="checkbox"
                onChange={(e) => setCheckedRememberMe(e.target.checked)}
                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
              />
              <label
                htmlFor="remember-me"
                className="ml-2 block text-sm text-gray-900"
              >
                Remember me
              </label>
            </div>

            <div className="text-sm">
              <p
                onClick={() => navigate(FORGOT_PASSWORD)}
                className="cursor-pointer font-medium text-indigo-600 hover:text-indigo-500"
              >
                Forgot your password?
              </p>
            </div>
          </div>
          <div className="mt-8">
            <div className="text-red-500 text-sm mb-4"> {formError} </div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <LockClosedIcon
                  className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                  aria-hidden="true"
                />
              </span>
              {loading ? <LoadingSpinner /> : "Sign in"}
            </button>
          </div>
          <div className="flex items-center my-4 before:flex-1 before:border-t before:border-gray-300 before:mt-0.5 after:flex-1 after:border-t after:border-gray-300 after:mt-0.5">
            <p className="text-center font-semibold mx-4 mb-0">OR</p>
          </div>
          <GoogleLogin className="w-full flex justify-center" />
          <div className="flex justify-end mt-4">
            <button
              onClick={() => navigate("/")}
              className="font-medium text-sm text-indigo-600 hover:text-indigo-500"
            >
              Go back to Home
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default SignIn;
