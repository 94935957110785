import axios from "axios";
import { getAPIerror } from "../utils";
import userServices from "./userServices";

const client = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        "Content-Type": "application/json"
    }
});

// Response interceptor to handle errors
client.interceptors.response.use(
    response => {
        return {
            data: response.data,
            error: null,
        }
    },
    error => {
        let errorResponse = error.response;
        let statusCode = errorResponse.status;
        let errorMsg = getAPIerror(errorResponse);
        return {
            data: null,
            error: {
                status: statusCode,
                message: errorMsg,
            },
        }
    }
);


// Request interceptor to set auth headers
client.interceptors.request.use(
    config => {
        let user = userServices.getUser()
        if (user && user.token) {
            config.headers.Authorization = `Token ${user.token}`;
        }
        
        return config;
    }
);


export default client;