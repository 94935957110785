import { LockClosedIcon } from "@heroicons/react/solid";
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import authServices from "../../services/authServices";
import userServices  from "../../services/userServices";
import {LoadingSpinner} from "../../components/Commons";
import { DASHBOARD } from "../../constants/appNavigations";

function SignUp() {
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState("");
  const navigate = useNavigate();

  /**
   * Login user if success redirect to App Home page
   * @param {firstName, LastName, Email, Password} values 
   */
  const signUpAPIHandler = async (values) => {
    setLoading(true);
    let { data, error } = await authServices.userSignUp(values);

    if (data) {
      userServices.setUser(data);
      navigate(DASHBOARD);
    }

    if (error) {
      setFormError(error.message);
      setLoading(false);
    }
  }

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .max(15, "Must be 15 characters or less")
        .required("Required"),
      lastName: Yup.string()
        .max(15, "Must be 15 characters or less")
        .required("Required"),
      email: Yup.string().email("Enter valid email").required("Required"),
      password: Yup.string()
        .max(15, "Must be 15 characters or less")
        .min(6, "Need at least 6 characters")
        .required("Required"),
    }),
    onSubmit: async (values) => signUpAPIHandler(values),
  });

  useEffect(() => {
    let user = userServices.getUser();
    if (user) {
      navigate(DASHBOARD);
    }
  }, [navigate]);

  return (
    <div className="min-h-screen flex justify-center items-center">
      <form
        className="w-full max-w-md my-10 px-5"
        onSubmit={formik.handleSubmit}
      >
        <div>
          <h1 className="text-center text-3xl font-extrabold">ARJUN</h1>
          <h2 className="mt-4 mb-10 text-center text-3xl font-extrabold text-gray-900">
            Sign Up for an account
          </h2>
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-first-name"
            >
              First Name
            </label>
            <input
              className="appearance-none block w-full placeholder:text-sm text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white"
              id="grid-first-name"
              type="text"
              placeholder="First Name"
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors.firstName && <p className="text-red-500 text-xs mt-3">
              {formik.touched.firstName && formik.errors.firstName
                ? formik.errors.firstName
                : ""}
            </p>}
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-last-name"
            >
              Last Name
            </label>
            <input
              className="appearance-none block w-full placeholder:text-sm text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-last-name"
              type="text"
              placeholder="Last Name"
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors.firstName && <p className="text-red-500 text-xs mt-3">
              {formik.touched.lastName && formik.errors.lastName
                ? formik.errors.lastName
                : ""}
            </p>}
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full px-3">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-email"
            >
              Email
            </label>
            <input
              className="appearance-none block w-full placeholder:text-sm text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-email"
              type="email"
              placeholder="Email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors.email && <p className="text-red-500 text-xs mt-3">
              {formik.touched.email && formik.errors.email
                ? formik.errors.email
                : ""}
            </p>}
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full px-3">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              Password
            </label>
            <input
              className="appearance-none block w-full placeholder:text-sm text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-password"
              type="password"
              placeholder="Password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors.password && <p className="text-red-500 text-xs mt-3">
              {formik.touched.password && formik.errors.password
                ? formik.errors.password
                : ""}
            </p>}
          </div>
        </div>
        <div className="mt-8">
          <div className="text-red-500 text-sm mb-4"> {formError} </div>
          <button
            type="submit"
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <span className="absolute left-0 inset-y-0 flex items-center pl-3">
              <LockClosedIcon
                className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                aria-hidden="true"
              />
            </span>
            {loading ? <LoadingSpinner /> : "Sign up"}
          </button>
        </div>
        <div className="flex justify-end mt-6">
          <div className="text-sm">
            <button
              onClick={() => navigate("/login")}
              className="font-medium text-indigo-600 hover:text-indigo-500"
            >
              Already Signed up? Go to login
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default SignUp;
