import React from 'react'
import { Switch } from '@headlessui/react'

function ToggleButton({ isActive, onChange }) {
    
    const onChangeHandler = (event) => {
        onChange(event);
    }
    return (
        <Switch
            checked={isActive}
            onChange={onChangeHandler}
            className={`${isActive ? "bg-teal-900" : "bg-gray-200"} relative inline-flex h-[24px] w-[48px] 
            shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-150 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
        >
            <span
                aria-hidden="true"
                className={`${isActive ? "translate-x-6" : "translate-x-0"} pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-150 ease-in-out`}
            />
        </Switch>
    )
}

export default ToggleButton