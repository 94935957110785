import client from "./axiosConfig";

function getUser() {
  const user = localStorage.getItem("userInfo");
  return JSON.parse(user);
}

async function getUserProfile() {
  const response = await client.get("/users/");
  return response;
}

function checkRememberMe() {
  const user = localStorage.getItem("rememberMeInfo");
  return JSON.parse(user);
}

function setRememberMe(params) {
  localStorage.setItem("rememberMeInfo", JSON.stringify(params));
}

function clearRememberMe() {
  localStorage.removeItem("rememberMeInfo");
}

function setUser(params) {
  localStorage.setItem("userInfo", JSON.stringify(params));
}

function logoutUser() {
  localStorage.removeItem("userInfo");
}

async function forgotPassword(params) {
  try {
    const config = {
      method: "get",
      url: "/forgot-password",
      data: params,
    };
    const response = await client(config);
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
}

async function updateProfile(params) {
  const config = {
    method: 'patch',
    url: '/users/',
    data: params,
  }
  const response = await client(config);
  return response;
}

const userServices = {
  getUser,
  checkRememberMe,
  setRememberMe,
  clearRememberMe,
  setUser,
  logoutUser,
  getUserProfile,
  forgotPassword,
  updateProfile
};
export default userServices;
