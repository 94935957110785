import client from "./axiosConfig";

async function userLogin(params) {
  const data = JSON.stringify(params);
  const config = {
    method: "post",
    url: "/auth/login/",
    data: data,
  };
  const response = await client(config);
  return response;
}

async function userSignUp(params) {
  const data = JSON.stringify(params);
  const config = {
    method: "post",
    url: "/users/signup/",
    data: data,
  };
  const response = await client(config);
  return response;
}

const authServices = { userLogin, userSignUp };
export default authServices;
