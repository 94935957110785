import * as Yup from "yup";
import { useState } from "react";
import { useFormik } from "formik";
import { Common } from "../"
import userServices from "../../services/userServices";
import toast from "react-hot-toast";
import { getAPIerror } from "../../utils";

export default function ResetPassword() {
  const [isLoading, setIsLoading] = useState(false)
  const [resetPassword, setResetPassword] = useState({
    password: "",
    confirmPassword: "",
  });

  const resetPasswordHandler = async (values) => {
    setIsLoading(true);
    try {
      const response = await userServices.updateProfile({
        password: values['password']
      })
      if (response.data) {
        toast.success("Password updated successfully!")
        formikResetPassword.resetForm();
      } else {
        const errorMsg = getAPIerror(response);
        toast.error(errorMsg);
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again later.")
    } finally {
      setIsLoading(false);
    }
  };

  const formikResetPassword = useFormik({
    initialValues: resetPassword,
    enableReinitialize: true,
    validationSchema: Yup.object({
      password: Yup.string()
        .required("Required")
        .min(8, 'Password must have 8 characters.'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password')], "Password doesn't match")
        .required("Required")
    }),
    onSubmit: async (values) => resetPasswordHandler(values),
  });

  return (
    <div className="mt-10 sm:mt-0">
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Reset Password
            </h3>
          </div>
        </div>
        <div className="mt-5 md:mt-0 md:col-span-2">
          <form onSubmit={formikResetPassword.handleSubmit}>
            <div className="shadow overflow-hidden sm:rounded-md">
              <div className="px-4 py-5 bg-white sm:p-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="newPassword"
                      className="block text-sm font-medium text-gray-700"
                    >
                      New Password
                    </label>
                    <input
                      type="password"
                      name="password"
                      id="password"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      value={formikResetPassword.values.password}
                      onChange={formikResetPassword.handleChange}
                      onBlur={formikResetPassword.handleBlur}
                    />
                    {
                      formikResetPassword.errors.password &&
                      <p className="text-red-500 text-xs mt-3">
                        {formikResetPassword.errors.password}
                      </p>
                    }
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="confirmPassword"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Confirm Password
                    </label>
                    <input
                      type="password"
                      name="confirmPassword"
                      id="confirmPassword"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      value={formikResetPassword.values.confirmPassword}
                      onChange={formikResetPassword.handleChange}
                      onBlur={formikResetPassword.handleBlur}
                    />
                    {
                      formikResetPassword.errors.confirmPassword &&
                      <p className="text-red-500 text-xs mt-3">
                        {formikResetPassword.errors.confirmPassword}
                      </p>
                    }
                  </div>
                </div>
              </div>

              <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <button
                  type="submit"
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {isLoading ? <Common.LoadingSpinner /> : 'Reset Password'}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
