import "./App.css";
import { Route, Routes } from "react-router-dom";
import {
  DashBoard,
  Layout,
  Common,
} from "./components";
import {
  SignIn,
  SignUp,
  HeroPage,
  ForgotPassword,
  Account,
  APIKeys,
  UseCases,
} from "./pages";
import * as appRoutes from "./constants/appNavigations";

function App() {
  return (
    <div className="App h-full bg-gray-100">
      <Routes>
        <Route path={appRoutes.HOME} element={<HeroPage />} />
        <Route path={appRoutes.LOGIN} element={<SignIn />} />
        <Route path={appRoutes.SIGNUP} element={<SignUp />} />
        <Route path={appRoutes.FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route path={appRoutes.APP_HOME} element={<Layout.AppLayout />}>
          <Route path={appRoutes.DASHBOARD} element={<DashBoard.DashBoard />} />
          <Route path={appRoutes.USECASES} element={<UseCases />} />
          <Route path={appRoutes.API_KEYS} element={<APIKeys />} />
          <Route path={appRoutes.PROFILE} element={<Account />} />
        </Route>
        <Route path="*" element={<Common.NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
