import React from 'react'

export default function ButtonSkeleton({ show = true }) {

    return (
        <>
            {show && (
                <button className="bg-white p-4 rounded w-32">
                    <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                </button>
            )}
        </>

    )
}
