import React from 'react'

export default function CardSkeleton({ show = true }) {

    return (
        <>
            {show && (
                <div                 
                className="block p-6 h-48 md:w-96 sm:w-60 rounded-md shadow-md bg-white opacity-60"
                >
                    <div className="h-3 bg-slate-200 rounded animate-pulse col-span-2 w-36"></div>
                    <div className="h-24 mt-3 bg-slate-200  animate-pulse rounded col-span-2"></div>
                    <div className="flex justify-end bottom-2  animate-pulse right-1 w-full p-1">
                        <div className="h-6 bg-slate-200  animate-pulse rounded col-span-2 w-24"></div>
                    </div>
                </div>
            )}
        </>

    )
}
