import apiKeyService from "../../services/apiKeyService";
import React from "react";
import { dateFormatter } from "../../utils";
import {CopyToClipboard,ToggleButton} from "../Commons";

function KeyListIndividual(props) {
  const [isActive, setIsActive] = React.useState(props.isActive);

  const handleDelete = () => {
    props.deleteKeyFn(props.id);
  };
  
  const handleEdit = () => {
    props.editKeyFn(props.id);
  };

  const handleChangeStatus = async (value) => {
    const data = {
      is_enabled: value,
    }
    const response = await apiKeyService.updateKey(props.id, data);
    if(response.data){
      setIsActive(value);
    }
  };

  return (
    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
      <th
        scope="row"
        className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
      >
        {props.title}
      </th>
      <td className="px-6 py-4">
        {props.apikey}
        <CopyToClipboard textToCopy={props.apikey}></CopyToClipboard>
      </td>
      <td className="px-6 py-4">{dateFormatter(props.modified)}</td>
      <td className="px-6 py-4">
        <ToggleButton isActive={isActive} onChange={handleChangeStatus} />
      </td>
      <td className="px-6 py-4 space-x-5">
        <button
          onClick={handleEdit}
          className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
        >
          Edit
        </button>
        <button
          onClick={handleDelete}
          className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
        >
          Delete
        </button>
      </td>
    </tr>
  );
}

export default KeyListIndividual;
