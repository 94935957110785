import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Common } from "../"
import toast from 'react-hot-toast';
import userServices from "../../services/userServices";
import countries from "../../constants/countries";

export default function ProfileInfo() {
  const [isLoading, setIsLoading] = useState(false)
  const [userProfile, setUserProfile] = useState({
    first_name: '',
    last_name: '',
    email: '',
    country: '',
  });

  const profileUpdateHandler = async (values) => {
    setIsLoading(true);
    try {
      const profile = await userServices.updateProfile(values);
      setUserProfile(profile.data);
      toast.success("Profile updated successfully!")
    }
    catch (e) {
      toast.error("Something went wrong! Please try again later.")
    } finally {
      setIsLoading(false);
    }
  };

  const formikUserInfo = useFormik({
    initialValues: userProfile,
    enableReinitialize: true,
    validationSchema: Yup.object({
      email: Yup.string().email("Enter valid email").required("Email is required"),
      first_name: Yup.string().required("First name is required"),
      last_name: Yup.string().required("Last name is required"),
      country: Yup.string(),
    }),
    onSubmit: async (values) => {
      profileUpdateHandler(values)
    },
  });

  useEffect(() => {
    userServices.getUserProfile().then((response) => {
      if (response.data) {
        setUserProfile(response.data)
      }
    });
  }, []);

  return (
    <div className="mt-10 sm:mt-0">
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Personal Information
            </h3>
          </div>
        </div>
        <div className="mt-5 md:mt-0 md:col-span-2">
          <form onSubmit={formikUserInfo.handleSubmit}>
            <div className="shadow overflow-hidden sm:rounded-md">
              <div className="px-4 py-5 bg-white sm:p-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      First name
                    </label>
                    <input
                      type="text"
                      name="first_name"
                      id="first-name"
                      autoComplete="given-name"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      value={formikUserInfo.values.first_name}
                      onChange={formikUserInfo.handleChange}
                      onBlur={formikUserInfo.handleBlur}
                    />
                    {
                      formikUserInfo.errors.first_name &&
                      <p className="text-red-500 text-xs mt-3">
                        {formikUserInfo.errors.first_name}
                      </p>
                    }
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="last-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Last name
                    </label>
                    <input
                      type="text"
                      name="last_name"
                      id="last-name"
                      autoComplete="family-name"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      value={formikUserInfo.values.last_name}
                      onChange={formikUserInfo.handleChange}
                      onBlur={formikUserInfo.handleBlur}
                    />
                    {
                      formikUserInfo.errors.last_name &&
                      <p className="text-red-500 text-xs mt-3">
                        {formikUserInfo.errors.last_name}
                      </p>
                    }
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="email-address"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Email address
                    </label>
                    <input
                      type="text"
                      name="email"
                      id="email-address"
                      autoComplete="email"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      value={formikUserInfo.values.email}
                      onChange={formikUserInfo.handleChange}
                      onBlur={formikUserInfo.handleBlur}
                    />
                    {
                      formikUserInfo.errors.email &&
                      <p className="text-red-500 text-xs mt-3">
                        {formikUserInfo.errors.email}
                      </p>
                    }
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="country"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Country
                    </label>
                    <select
                      id="country"
                      name="country"
                      value={formikUserInfo.values.country}
                      onChange={formikUserInfo.handleChange}
                      onBlur={formikUserInfo.handleBlur}
                      autoComplete="country-name"
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                      <option value="">Select country</option>
                      {
                        countries.map(country => {
                          return <option value={country.shortname} key={country.id}>
                            {country.name}
                          </option>
                        })
                      }
                    </select>
                    {
                      formikUserInfo.errors.country &&
                      <p className="text-red-500 text-xs mt-3">
                        {formikUserInfo.errors.country}
                      </p>
                    }
                  </div>
                </div>
              </div>
              <div className="flex justify-end px-4 py-3 bg-gray-50 text-right sm:px-6">
                <button
                  type="submit"
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {isLoading ? <Common.LoadingSpinner /> : 'Update Profile'}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
