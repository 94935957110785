import React from "react";

function IndicatorBadge(props) {
  return (
    <>
      {props.isActive ? (
        <span className="bg-green-100 text-green-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900">
          Enabled
        </span>
      ) : (
        <span className="bg-gray-100 text-gray-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">
          Disabled
        </span>
      )}
    </>
  );
}

export default IndicatorBadge;
