import { Toaster } from 'react-hot-toast';


export default function CustomToast() {
    return (
        <Toaster
            position="top-right"
            toastOptions={{
                duration: 3000,
                style: {
                    fontSize: "14px"
                }
            }}
        />
    )
}