import userServices from "../services/userServices";

/**
 * Convert date to human readable format
 * @param {date object} inputDate
 * @returns {string} local date time
 */
export function dateFormatter(inputDate) {
  let date = new Date(inputDate);
  return date.toLocaleString();
}

/**
 * Convert a string to a Capitalized string
 * @param {string} string
 * @returns {string} Capitalized string
 */
export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Process response errors and return only first error message
 * @param {object} response - response object from API
 * @return {string} - First error message
 */
export function getAPIerror(response) {
  if (!response) {
    return "Something went wrong. Please try again later.";
  }

  let data = response.data;
  let errors = [];

  if ("errors" in data && Object.keys(data["errors"]).length > 0) {
    Object.keys(data["errors"]).map(key => {
      const msg = `${capitalize(key)}: ${data["errors"][key][0]}`
      errors.push(msg);
    })
    return capitalize(errors[0]);
  }
  return "Something went wrong! Please try later.";
}

/**
 * Greet user according to time of day
 */
export async function greetUser() {
  let user = "";
  let greet = "Hello";

  let userProfile = await userServices.getUserProfile();
  if (userProfile.data) {
    user = userProfile.data.firstName
      ? userProfile.data.firstName
      : userProfile.data.email;
  }

  let hrs = new Date().getHours();
  if (hrs < 12) greet = "Good Morning";
  else if (hrs >= 12 && hrs <= 17) greet = "Good Afternoon";
  else if (hrs >= 17 && hrs <= 24) greet = "Good Evening";

  return `${greet}${user ? ", " + user : ""}!`;
}

export function getContentTitle(path) {
  switch (path) {
    case "/app/dashboard":
      return "Dashboard";
    case "/app/usecases":
      return "Usecases";
    case "/app/api-keys":
      return "API Keys";
    case "/app/profile":
      return "Profile";
    default:
      break;
  }
  return "";
}

export function comparePasswords(values) {
  const { newPassword, confirmPassword } = values;
  if (newPassword !== confirmPassword) return `Password doesn't match`;
  return "";
}
