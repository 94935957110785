import { Outlet, useLocation } from "react-router-dom";
import { Common, Auth } from "..";
import { getContentTitle } from "../../utils";

function AppLayout() {
  const pathname = useLocation().pathname;
  return (
    <Auth.ProtectedRoute>
      <Common.Topbar />
      <Common.Content title={getContentTitle(pathname)}>
        <Common.CustomToast />
        <Outlet />
      </Common.Content>
    </Auth.ProtectedRoute>
  );
}

export default AppLayout;
