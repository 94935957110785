import client from "./axiosConfig";

async function getKeys() {
    let config = {
        method: 'get',
        url: '/auth/api-keys/',
    };
    let response = await client(config);
    return response;
}

async function getKeyById(id) {
    let config = {
        method: 'get',
        url: `/auth/api-keys/${id}/`,
    };
    let response = await client(config);
    return response;
}

async function createKey(data) {
    let key_name = JSON.stringify(data);
    let config = {
        method: 'post',
        url: '/auth/api-keys/',
        data: key_name
    };
    let response = await client(config);
    return response;
}

async function updateKey(id, data) {
    data = JSON.stringify(data);
    let config = {
        method: 'patch',
        url: `/auth/api-keys/${id}/`,
        data: data
    };
    let response = await client(config);
    return response;
}

async function deleteKey(id) {
    let config = {
        method: 'delete',
        url: `/auth/api-keys/${id}/`,
    };
    let response = await client(config);
    return response;
}

const apiKeyService = { getKeys, createKey, deleteKey, updateKey, getKeyById };
export default apiKeyService;