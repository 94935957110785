import client from './axiosConfig';

async function getUseCases() {
    let config = {
        'method': 'get',
        'url': '/usecases/'
    };    
    const response = await client(config);
    return response;
}

async function updateUseCaseStatus(useCaseId, status) {
    let data = {
        'usecase': useCaseId,
        'is_enabled': status
    }
    let config = {
        'method': 'post',
        'url': 'usecases/status/',
        'data': data
    };    
    const response = await client(config);
    return response;
}

const useCasesServices = { getUseCases, updateUseCaseStatus };
export default useCasesServices;