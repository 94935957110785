import React from "react";
import {
  ProfileInfo,
  ResetPassword,
} from "../../components/Auth";

export default function Account() {
  return (
    <div className="h-[90vh] overflow-y-scroll max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
      <ProfileInfo />
      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200"></div>
        </div>
      </div>
      <ResetPassword />
    </div>
  );
}
