import { LockClosedIcon } from "@heroicons/react/solid";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import userServices from "../../services/userServices";
import LoadingSpinner from "../../components/Commons/LoadingSpinner";
import {LOGIN} from "../../constants/appNavigations";

function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState({ email: "" });
  const [formError, setFormError] = useState("");

  const navigate = useNavigate();

  /**
   * Backend call to send link to user 
   * @param {email} values
   * */
  const forgotPasswordHandler = async (values) => {
      try {
        setLoading(true);
        setEmail(values);
        const response = await userServices.forgotPassword(values);
        console.log(response);
        setLoading(false)
      } catch (error) {
        setFormError(error.message);
        setLoading(false);
      }
  }

  const formik = useFormik({
    initialValues: email,
    enableReinitialize: true,
    validationSchema: Yup.object({
      email: Yup.string().email("Enter valid email address").required("Required"),
    }),
    onSubmit: async (values) => forgotPasswordHandler(values)
  });

  return (
      <div className="min-h-screen flex justify-center items-center">
        <form
          className="w-full max-w-md my-10 px-5"
          onSubmit={formik.handleSubmit}
        >
          <div>
            <h1 className="text-center text-3xl font-extrabold">ARJUN</h1>
            <h2 className="mt-4 mb-5 text-center text-3xl font-extrabold text-gray-900">
              Forgot Password?
            </h2>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-email"
              >
                Email
              </label>
              <input
                className="appearance-none block w-full placeholder:text-sm text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-email"
                type="email"
                placeholder="Email Address"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {(formik.errors.email && formik.touched.email) && <p className="text-red-500 text-xs mt-3">
                {formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : ""}
              </p>}
            </div>
          </div>
          <div className="mt-8">
            <div className="text-red-500 text-sm mb-4"> {formError} </div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <LockClosedIcon
                  className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                  aria-hidden="true"
                />
              </span>
              {loading ? <LoadingSpinner /> : "Reset Password"}
            </button>
          </div>
          <div className="flex justify-end mt-4">
            <button
              onClick={() => navigate(LOGIN)}
              className="font-medium text-sm text-indigo-600 hover:text-indigo-500"
            >
              Go back to Login
            </button>
          </div>
        </form>
      </div>
  );
}

export default ForgotPassword;
