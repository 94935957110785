const navigation = [
  {
    name: "Documentation",
    href: "documentation",
    newPage: false,
  },
  {
    name: "Pricing",
    href: "pricing",
    newPage: false,
  },
  {
    name: "Service Status",
    href: "https://uptime.statuscake.com/?TestID=evQqVTvA0T",
    newPage: true,
  },
];

export default navigation;
