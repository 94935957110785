import React, { useEffect, useState } from "react";
import {
  UseCase,
  Skeleton,
} from "../../components"
import useCasesServices from "../../services/useCasesServices";

function UseCases() {
  const [useCases, setUseCases] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [shouldRefresh, setShouldRefresh] = useState(1);

  const refreshList = () => {
    setShouldRefresh(shouldRefresh + 1);
  }

  /**
   * Refresh the list of use cases after side over is closed
   * to show the latest use cases.
   */
  const slideOverCloseHandler = () => {
    refreshList();
  }

  useEffect(() => {
    async function getUseCases() {
      setIsLoading(true);
      let usecases = await useCasesServices.getUseCases();
      if (usecases.data) {
        setUseCases(usecases.data.results);
      }
      setIsLoading(false);
    }
    getUseCases();
  }, [shouldRefresh]);

  return (
    <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 min-h-[calc(100vh-4rem-84px)]">
      <div className="flex flex-wrap gap-8">
        {!isLoading && useCases.map((useCase) => (
          <UseCase.UseCaseCard data={useCase} key={useCase.id} slideOverCloseHandler={slideOverCloseHandler} />
        ))}
        {
          isLoading && Array(6).fill(0).map((data, idx) => (
            <Skeleton.CardSkeleton key={`useCase-${idx}.${data}`} />
          ))
        }
      </div>
    </div>
  );
}

export default UseCases;
