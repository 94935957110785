export default [
    {
        id: "1",
        shortname: "AF",
        name: "Afghanistan",
        phonecode: "93"
    },
    {
        id: "2",
        shortname: "AL",
        name: "Albania",
        phonecode: "355"
    },
    {
        id: "3",
        shortname: "DZ",
        name: "Algeria",
        phonecode: "213"
    },
    {
        id: "4",
        shortname: "AS",
        name: "American Samoa",
        phonecode: "1684"
    },
    {
        id: "5",
        shortname: "AD",
        name: "Andorra",
        phonecode: "376"
    },
    {
        id: "6",
        shortname: "AO",
        name: "Angola",
        phonecode: "244"
    },
    {
        id: "7",
        shortname: "AI",
        name: "Anguilla",
        phonecode: "1264"
    },
    {
        id: "8",
        shortname: "AQ",
        name: "Antarctica",
        phonecode: "0"
    },
    {
        id: "9",
        shortname: "AG",
        name: "Antigua And Barbuda",
        phonecode: "1268"
    },
    {
        id: "10",
        shortname: "AR",
        name: "Argentina",
        phonecode: "54"
    },
    {
        id: "11",
        shortname: "AM",
        name: "Armenia",
        phonecode: "374"
    },
    {
        id: "12",
        shortname: "AW",
        name: "Aruba",
        phonecode: "297"
    },
    {
        id: "13",
        shortname: "AU",
        name: "Australia",
        phonecode: "61"
    },
    {
        id: "14",
        shortname: "AT",
        name: "Austria",
        phonecode: "43"
    },
    {
        id: "15",
        shortname: "AZ",
        name: "Azerbaijan",
        phonecode: "994"
    },
    {
        id: "16",
        shortname: "BS",
        name: "Bahamas The",
        phonecode: "1242"
    },
    {
        id: "17",
        shortname: "BH",
        name: "Bahrain",
        phonecode: "973"
    },
    {
        id: "18",
        shortname: "BD",
        name: "Bangladesh",
        phonecode: "880"
    },
    {
        id: "19",
        shortname: "BB",
        name: "Barbados",
        phonecode: "1246"
    },
    {
        id: "20",
        shortname: "BY",
        name: "Belarus",
        phonecode: "375"
    },
    {
        id: "21",
        shortname: "BE",
        name: "Belgium",
        phonecode: "32"
    },
    {
        id: "22",
        shortname: "BZ",
        name: "Belize",
        phonecode: "501"
    },
    {
        id: "23",
        shortname: "BJ",
        name: "Benin",
        phonecode: "229"
    },
    {
        id: "24",
        shortname: "BM",
        name: "Bermuda",
        phonecode: "1441"
    },
    {
        id: "25",
        shortname: "BT",
        name: "Bhutan",
        phonecode: "975"
    },
    {
        id: "26",
        shortname: "BO",
        name: "Bolivia",
        phonecode: "591"
    },
    {
        id: "27",
        shortname: "BA",
        name: "Bosnia and Herzegovina",
        phonecode: "387"
    },
    {
        id: "28",
        shortname: "BW",
        name: "Botswana",
        phonecode: "267"
    },
    {
        id: "29",
        shortname: "BV",
        name: "Bouvet Island",
        phonecode: "0"
    },
    {
        id: "30",
        shortname: "BR",
        name: "Brazil",
        phonecode: "55"
    },
    {
        id: "31",
        shortname: "IO",
        name: "British Indian Ocean Territory",
        phonecode: "246"
    },
    {
        id: "32",
        shortname: "BN",
        name: "Brunei",
        phonecode: "673"
    },
    {
        id: "33",
        shortname: "BG",
        name: "Bulgaria",
        phonecode: "359"
    },
    {
        id: "34",
        shortname: "BF",
        name: "Burkina Faso",
        phonecode: "226"
    },
    {
        id: "35",
        shortname: "BI",
        name: "Burundi",
        phonecode: "257"
    },
    {
        id: "36",
        shortname: "KH",
        name: "Cambodia",
        phonecode: "855"
    },
    {
        id: "37",
        shortname: "CM",
        name: "Cameroon",
        phonecode: "237"
    },
    {
        id: "38",
        shortname: "CA",
        name: "Canada",
        phonecode: "1"
    },
    {
        id: "39",
        shortname: "CV",
        name: "Cape Verde",
        phonecode: "238"
    },
    {
        id: "40",
        shortname: "KY",
        name: "Cayman Islands",
        phonecode: "1345"
    },
    {
        id: "41",
        shortname: "CF",
        name: "Central African Republic",
        phonecode: "236"
    },
    {
        id: "42",
        shortname: "TD",
        name: "Chad",
        phonecode: "235"
    },
    {
        id: "43",
        shortname: "CL",
        name: "Chile",
        phonecode: "56"
    },
    {
        id: "44",
        shortname: "CN",
        name: "China",
        phonecode: "86"
    },
    {
        id: "45",
        shortname: "CX",
        name: "Christmas Island",
        phonecode: "61"
    },
    {
        id: "46",
        shortname: "CC",
        name: "Cocos (Keeling) Islands",
        phonecode: "672"
    },
    {
        id: "47",
        shortname: "CO",
        name: "Colombia",
        phonecode: "57"
    },
    {
        id: "48",
        shortname: "KM",
        name: "Comoros",
        phonecode: "269"
    },
    {
        id: "49",
        shortname: "CG",
        name: "Congo",
        phonecode: "242"
    },
    {
        id: "50",
        shortname: "CD",
        name: "Congo The Democratic Republic Of The",
        phonecode: "242"
    },
    {
        id: "51",
        shortname: "CK",
        name: "Cook Islands",
        phonecode: "682"
    },
    {
        id: "52",
        shortname: "CR",
        name: "Costa Rica",
        phonecode: "506"
    },
    {
        id: "53",
        shortname: "CI",
        name: "Cote D''Ivoire (Ivory Coast)",
        phonecode: "225"
    },
    {
        id: "54",
        shortname: "HR",
        name: "Croatia (Hrvatska)",
        phonecode: "385"
    },
    {
        id: "55",
        shortname: "CU",
        name: "Cuba",
        phonecode: "53"
    },
    {
        id: "56",
        shortname: "CY",
        name: "Cyprus",
        phonecode: "357"
    },
    {
        id: "57",
        shortname: "CZ",
        name: "Czech Republic",
        phonecode: "420"
    },
    {
        id: "58",
        shortname: "DK",
        name: "Denmark",
        phonecode: "45"
    },
    {
        id: "59",
        shortname: "DJ",
        name: "Djibouti",
        phonecode: "253"
    },
    {
        id: "60",
        shortname: "DM",
        name: "Dominica",
        phonecode: "1767"
    },
    {
        id: "61",
        shortname: "DO",
        name: "Dominican Republic",
        phonecode: "1809"
    },
    {
        id: "62",
        shortname: "TP",
        name: "East Timor",
        phonecode: "670"
    },
    {
        id: "63",
        shortname: "EC",
        name: "Ecuador",
        phonecode: "593"
    },
    {
        id: "64",
        shortname: "EG",
        name: "Egypt",
        phonecode: "20"
    },
    {
        id: "65",
        shortname: "SV",
        name: "El Salvador",
        phonecode: "503"
    },
    {
        id: "66",
        shortname: "GQ",
        name: "Equatorial Guinea",
        phonecode: "240"
    },
    {
        id: "67",
        shortname: "ER",
        name: "Eritrea",
        phonecode: "291"
    },
    {
        id: "68",
        shortname: "EE",
        name: "Estonia",
        phonecode: "372"
    },
    {
        id: "69",
        shortname: "ET",
        name: "Ethiopia",
        phonecode: "251"
    },
    {
        id: "70",
        shortname: "XA",
        name: "External Territories of Australia",
        phonecode: "61"
    },
    {
        id: "71",
        shortname: "FK",
        name: "Falkland Islands",
        phonecode: "500"
    },
    {
        id: "72",
        shortname: "FO",
        name: "Faroe Islands",
        phonecode: "298"
    },
    {
        id: "73",
        shortname: "FJ",
        name: "Fiji Islands",
        phonecode: "679"
    },
    {
        id: "74",
        shortname: "FI",
        name: "Finland",
        phonecode: "358"
    },
    {
        id: "75",
        shortname: "FR",
        name: "France",
        phonecode: "33"
    },
    {
        id: "76",
        shortname: "GF",
        name: "French Guiana",
        phonecode: "594"
    },
    {
        id: "77",
        shortname: "PF",
        name: "French Polynesia",
        phonecode: "689"
    },
    {
        id: "78",
        shortname: "TF",
        name: "French Southern Territories",
        phonecode: "0"
    },
    {
        id: "79",
        shortname: "GA",
        name: "Gabon",
        phonecode: "241"
    },
    {
        id: "80",
        shortname: "GM",
        name: "Gambia The",
        phonecode: "220"
    },
    {
        id: "81",
        shortname: "GE",
        name: "Georgia",
        phonecode: "995"
    },
    {
        id: "82",
        shortname: "DE",
        name: "Germany",
        phonecode: "49"
    },
    {
        id: "83",
        shortname: "GH",
        name: "Ghana",
        phonecode: "233"
    },
    {
        id: "84",
        shortname: "GI",
        name: "Gibraltar",
        phonecode: "350"
    },
    {
        id: "85",
        shortname: "GR",
        name: "Greece",
        phonecode: "30"
    },
    {
        id: "86",
        shortname: "GL",
        name: "Greenland",
        phonecode: "299"
    },
    {
        id: "87",
        shortname: "GD",
        name: "Grenada",
        phonecode: "1473"
    },
    {
        id: "88",
        shortname: "GP",
        name: "Guadeloupe",
        phonecode: "590"
    },
    {
        id: "89",
        shortname: "GU",
        name: "Guam",
        phonecode: "1671"
    },
    {
        id: "90",
        shortname: "GT",
        name: "Guatemala",
        phonecode: "502"
    },
    {
        id: "91",
        shortname: "XU",
        name: "Guernsey and Alderney",
        phonecode: "44"
    },
    {
        id: "92",
        shortname: "GN",
        name: "Guinea",
        phonecode: "224"
    },
    {
        id: "93",
        shortname: "GW",
        name: "Guinea-Bissau",
        phonecode: "245"
    },
    {
        id: "94",
        shortname: "GY",
        name: "Guyana",
        phonecode: "592"
    },
    {
        id: "95",
        shortname: "HT",
        name: "Haiti",
        phonecode: "509"
    },
    {
        id: "96",
        shortname: "HM",
        name: "Heard and McDonald Islands",
        phonecode: "0"
    },
    {
        id: "97",
        shortname: "HN",
        name: "Honduras",
        phonecode: "504"
    },
    {
        id: "98",
        shortname: "HK",
        name: "Hong Kong S.A.R.",
        phonecode: "852"
    },
    {
        id: "99",
        shortname: "HU",
        name: "Hungary",
        phonecode: "36"
    },
    {
        id: "100",
        shortname: "IS",
        name: "Iceland",
        phonecode: "354"
    },
    {
        id: "101",
        shortname: "IN",
        name: "India",
        phonecode: "91"
    },
    {
        id: "102",
        shortname: "ID",
        name: "Indonesia",
        phonecode: "62"
    },
    {
        id: "103",
        shortname: "IR",
        name: "Iran",
        phonecode: "98"
    },
    {
        id: "104",
        shortname: "IQ",
        name: "Iraq",
        phonecode: "964"
    },
    {
        id: "105",
        shortname: "IE",
        name: "Ireland",
        phonecode: "353"
    },
    {
        id: "106",
        shortname: "IL",
        name: "Israel",
        phonecode: "972"
    },
    {
        id: "107",
        shortname: "IT",
        name: "Italy",
        phonecode: "39"
    },
    {
        id: "108",
        shortname: "JM",
        name: "Jamaica",
        phonecode: "1876"
    },
    {
        id: "109",
        shortname: "JP",
        name: "Japan",
        phonecode: "81"
    },
    {
        id: "110",
        shortname: "XJ",
        name: "Jersey",
        phonecode: "44"
    },
    {
        id: "111",
        shortname: "JO",
        name: "Jordan",
        phonecode: "962"
    },
    {
        id: "112",
        shortname: "KZ",
        name: "Kazakhstan",
        phonecode: "7"
    },
    {
        id: "113",
        shortname: "KE",
        name: "Kenya",
        phonecode: "254"
    },
    {
        id: "114",
        shortname: "KI",
        name: "Kiribati",
        phonecode: "686"
    },
    {
        id: "115",
        shortname: "KP",
        name: "Korea North",
        phonecode: "850"
    },
    {
        id: "116",
        shortname: "KR",
        name: "Korea South",
        phonecode: "82"
    },
    {
        id: "117",
        shortname: "KW",
        name: "Kuwait",
        phonecode: "965"
    },
    {
        id: "118",
        shortname: "KG",
        name: "Kyrgyzstan",
        phonecode: "996"
    },
    {
        id: "119",
        shortname: "LA",
        name: "Laos",
        phonecode: "856"
    },
    {
        id: "120",
        shortname: "LV",
        name: "Latvia",
        phonecode: "371"
    },
    {
        id: "121",
        shortname: "LB",
        name: "Lebanon",
        phonecode: "961"
    },
    {
        id: "122",
        shortname: "LS",
        name: "Lesotho",
        phonecode: "266"
    },
    {
        id: "123",
        shortname: "LR",
        name: "Liberia",
        phonecode: "231"
    },
    {
        id: "124",
        shortname: "LY",
        name: "Libya",
        phonecode: "218"
    },
    {
        id: "125",
        shortname: "LI",
        name: "Liechtenstein",
        phonecode: "423"
    },
    {
        id: "126",
        shortname: "LT",
        name: "Lithuania",
        phonecode: "370"
    },
    {
        id: "127",
        shortname: "LU",
        name: "Luxembourg",
        phonecode: "352"
    },
    {
        id: "128",
        shortname: "MO",
        name: "Macau S.A.R.",
        phonecode: "853"
    },
    {
        id: "129",
        shortname: "MK",
        name: "Macedonia",
        phonecode: "389"
    },
    {
        id: "130",
        shortname: "MG",
        name: "Madagascar",
        phonecode: "261"
    },
    {
        id: "131",
        shortname: "MW",
        name: "Malawi",
        phonecode: "265"
    },
    {
        id: "132",
        shortname: "MY",
        name: "Malaysia",
        phonecode: "60"
    },
    {
        id: "133",
        shortname: "MV",
        name: "Maldives",
        phonecode: "960"
    },
    {
        id: "134",
        shortname: "ML",
        name: "Mali",
        phonecode: "223"
    },
    {
        id: "135",
        shortname: "MT",
        name: "Malta",
        phonecode: "356"
    },
    {
        id: "136",
        shortname: "XM",
        name: "Man (Isle of)",
        phonecode: "44"
    },
    {
        id: "137",
        shortname: "MH",
        name: "Marshall Islands",
        phonecode: "692"
    },
    {
        id: "138",
        shortname: "MQ",
        name: "Martinique",
        phonecode: "596"
    },
    {
        id: "139",
        shortname: "MR",
        name: "Mauritania",
        phonecode: "222"
    },
    {
        id: "140",
        shortname: "MU",
        name: "Mauritius",
        phonecode: "230"
    },
    {
        id: "141",
        shortname: "YT",
        name: "Mayotte",
        phonecode: "269"
    },
    {
        id: "142",
        shortname: "MX",
        name: "Mexico",
        phonecode: "52"
    },
    {
        id: "143",
        shortname: "FM",
        name: "Micronesia",
        phonecode: "691"
    },
    {
        id: "144",
        shortname: "MD",
        name: "Moldova",
        phonecode: "373"
    },
    {
        id: "145",
        shortname: "MC",
        name: "Monaco",
        phonecode: "377"
    },
    {
        id: "146",
        shortname: "MN",
        name: "Mongolia",
        phonecode: "976"
    },
    {
        id: "147",
        shortname: "MS",
        name: "Montserrat",
        phonecode: "1664"
    },
    {
        id: "148",
        shortname: "MA",
        name: "Morocco",
        phonecode: "212"
    },
    {
        id: "149",
        shortname: "MZ",
        name: "Mozambique",
        phonecode: "258"
    },
    {
        id: "150",
        shortname: "MM",
        name: "Myanmar",
        phonecode: "95"
    },
    {
        id: "151",
        shortname: "NA",
        name: "Namibia",
        phonecode: "264"
    },
    {
        id: "152",
        shortname: "NR",
        name: "Nauru",
        phonecode: "674"
    },
    {
        id: "153",
        shortname: "NP",
        name: "Nepal",
        phonecode: "977"
    },
    {
        id: "154",
        shortname: "AN",
        name: "Netherlands Antilles",
        phonecode: "599"
    },
    {
        id: "155",
        shortname: "NL",
        name: "Netherlands",
        phonecode: "31"
    },
    {
        id: "156",
        shortname: "NC",
        name: "New Caledonia",
        phonecode: "687"
    },
    {
        id: "157",
        shortname: "NZ",
        name: "New Zealand",
        phonecode: "64"
    },
    {
        id: "158",
        shortname: "NI",
        name: "Nicaragua",
        phonecode: "505"
    },
    {
        id: "159",
        shortname: "NE",
        name: "Niger",
        phonecode: "227"
    },
    {
        id: "160",
        shortname: "NG",
        name: "Nigeria",
        phonecode: "234"
    },
    {
        id: "161",
        shortname: "NU",
        name: "Niue",
        phonecode: "683"
    },
    {
        id: "162",
        shortname: "NF",
        name: "Norfolk Island",
        phonecode: "672"
    },
    {
        id: "163",
        shortname: "MP",
        name: "Northern Mariana Islands",
        phonecode: "1670"
    },
    {
        id: "164",
        shortname: "NO",
        name: "Norway",
        phonecode: "47"
    },
    {
        id: "165",
        shortname: "OM",
        name: "Oman",
        phonecode: "968"
    },
    {
        id: "166",
        shortname: "PK",
        name: "Pakistan",
        phonecode: "92"
    },
    {
        id: "167",
        shortname: "PW",
        name: "Palau",
        phonecode: "680"
    },
    {
        id: "168",
        shortname: "PS",
        name: "Palestinian Territory Occupied",
        phonecode: "970"
    },
    {
        id: "169",
        shortname: "PA",
        name: "Panama",
        phonecode: "507"
    },
    {
        id: "170",
        shortname: "PG",
        name: "Papua new Guinea",
        phonecode: "675"
    },
    {
        id: "171",
        shortname: "PY",
        name: "Paraguay",
        phonecode: "595"
    },
    {
        id: "172",
        shortname: "PE",
        name: "Peru",
        phonecode: "51"
    },
    {
        id: "173",
        shortname: "PH",
        name: "Philippines",
        phonecode: "63"
    },
    {
        id: "174",
        shortname: "PN",
        name: "Pitcairn Island",
        phonecode: "0"
    },
    {
        id: "175",
        shortname: "PL",
        name: "Poland",
        phonecode: "48"
    },
    {
        id: "176",
        shortname: "PT",
        name: "Portugal",
        phonecode: "351"
    },
    {
        id: "177",
        shortname: "PR",
        name: "Puerto Rico",
        phonecode: "1787"
    },
    {
        id: "178",
        shortname: "QA",
        name: "Qatar",
        phonecode: "974"
    },
    {
        id: "179",
        shortname: "RE",
        name: "Reunion",
        phonecode: "262"
    },
    {
        id: "180",
        shortname: "RO",
        name: "Romania",
        phonecode: "40"
    },
    {
        id: "181",
        shortname: "RU",
        name: "Russia",
        phonecode: "70"
    },
    {
        id: "182",
        shortname: "RW",
        name: "Rwanda",
        phonecode: "250"
    },
    {
        id: "183",
        shortname: "SH",
        name: "Saint Helena",
        phonecode: "290"
    },
    {
        id: "184",
        shortname: "KN",
        name: "Saint Kitts And Nevis",
        phonecode: "1869"
    },
    {
        id: "185",
        shortname: "LC",
        name: "Saint Lucia",
        phonecode: "1758"
    },
    {
        id: "186",
        shortname: "PM",
        name: "Saint Pierre and Miquelon",
        phonecode: "508"
    },
    {
        id: "187",
        shortname: "VC",
        name: "Saint Vincent And The Grenadines",
        phonecode: "1784"
    },
    {
        id: "188",
        shortname: "WS",
        name: "Samoa",
        phonecode: "684"
    },
    {
        id: "189",
        shortname: "SM",
        name: "San Marino",
        phonecode: "378"
    },
    {
        id: "190",
        shortname: "ST",
        name: "Sao Tome and Principe",
        phonecode: "239"
    },
    {
        id: "191",
        shortname: "SA",
        name: "Saudi Arabia",
        phonecode: "966"
    },
    {
        id: "192",
        shortname: "SN",
        name: "Senegal",
        phonecode: "221"
    },
    {
        id: "193",
        shortname: "RS",
        name: "Serbia",
        phonecode: "381"
    },
    {
        id: "194",
        shortname: "SC",
        name: "Seychelles",
        phonecode: "248"
    },
    {
        id: "195",
        shortname: "SL",
        name: "Sierra Leone",
        phonecode: "232"
    },
    {
        id: "196",
        shortname: "SG",
        name: "Singapore",
        phonecode: "65"
    },
    {
        id: "197",
        shortname: "SK",
        name: "Slovakia",
        phonecode: "421"
    },
    {
        id: "198",
        shortname: "SI",
        name: "Slovenia",
        phonecode: "386"
    },
    {
        id: "199",
        shortname: "XG",
        name: "Smaller Territories of the UK",
        phonecode: "44"
    },
    {
        id: "200",
        shortname: "SB",
        name: "Solomon Islands",
        phonecode: "677"
    },
    {
        id: "201",
        shortname: "SO",
        name: "Somalia",
        phonecode: "252"
    },
    {
        id: "202",
        shortname: "ZA",
        name: "South Africa",
        phonecode: "27"
    },
    {
        id: "203",
        shortname: "GS",
        name: "South Georgia",
        phonecode: "0"
    },
    {
        id: "204",
        shortname: "SS",
        name: "South Sudan",
        phonecode: "211"
    },
    {
        id: "205",
        shortname: "ES",
        name: "Spain",
        phonecode: "34"
    },
    {
        id: "206",
        shortname: "LK",
        name: "Sri Lanka",
        phonecode: "94"
    },
    {
        id: "207",
        shortname: "SD",
        name: "Sudan",
        phonecode: "249"
    },
    {
        id: "208",
        shortname: "SR",
        name: "Suriname",
        phonecode: "597"
    },
    {
        id: "209",
        shortname: "SJ",
        name: "Svalbard And Jan Mayen Islands",
        phonecode: "47"
    },
    {
        id: "210",
        shortname: "SZ",
        name: "Swaziland",
        phonecode: "268"
    },
    {
        id: "211",
        shortname: "SE",
        name: "Sweden",
        phonecode: "46"
    },
    {
        id: "212",
        shortname: "CH",
        name: "Switzerland",
        phonecode: "41"
    },
    {
        id: "213",
        shortname: "SY",
        name: "Syria",
        phonecode: "963"
    },
    {
        id: "214",
        shortname: "TW",
        name: "Taiwan",
        phonecode: "886"
    },
    {
        id: "215",
        shortname: "TJ",
        name: "Tajikistan",
        phonecode: "992"
    },
    {
        id: "216",
        shortname: "TZ",
        name: "Tanzania",
        phonecode: "255"
    },
    {
        id: "217",
        shortname: "TH",
        name: "Thailand",
        phonecode: "66"
    },
    {
        id: "218",
        shortname: "TG",
        name: "Togo",
        phonecode: "228"
    },
    {
        id: "219",
        shortname: "TK",
        name: "Tokelau",
        phonecode: "690"
    },
    {
        id: "220",
        shortname: "TO",
        name: "Tonga",
        phonecode: "676"
    },
    {
        id: "221",
        shortname: "TT",
        name: "Trinidad And Tobago",
        phonecode: "1868"
    },
    {
        id: "222",
        shortname: "TN",
        name: "Tunisia",
        phonecode: "216"
    },
    {
        id: "223",
        shortname: "TR",
        name: "Turkey",
        phonecode: "90"
    },
    {
        id: "224",
        shortname: "TM",
        name: "Turkmenistan",
        phonecode: "7370"
    },
    {
        id: "225",
        shortname: "TC",
        name: "Turks And Caicos Islands",
        phonecode: "1649"
    },
    {
        id: "226",
        shortname: "TV",
        name: "Tuvalu",
        phonecode: "688"
    },
    {
        id: "227",
        shortname: "UG",
        name: "Uganda",
        phonecode: "256"
    },
    {
        id: "228",
        shortname: "UA",
        name: "Ukraine",
        phonecode: "380"
    },
    {
        id: "229",
        shortname: "AE",
        name: "United Arab Emirates",
        phonecode: "971"
    },
    {
        id: "230",
        shortname: "GB",
        name: "United Kingdom",
        phonecode: "44"
    },
    {
        id: "231",
        shortname: "US",
        name: "United States",
        phonecode: "1"
    },
    {
        id: "232",
        shortname: "UM",
        name: "United States Minor Outlying Islands",
        phonecode: "1"
    },
    {
        id: "233",
        shortname: "UY",
        name: "Uruguay",
        phonecode: "598"
    },
    {
        id: "234",
        shortname: "UZ",
        name: "Uzbekistan",
        phonecode: "998"
    },
    {
        id: "235",
        shortname: "VU",
        name: "Vanuatu",
        phonecode: "678"
    },
    {
        id: "236",
        shortname: "VA",
        name: "Vatican City State (Holy See)",
        phonecode: "39"
    },
    {
        id: "237",
        shortname: "VE",
        name: "Venezuela",
        phonecode: "58"
    },
    {
        id: "238",
        shortname: "VN",
        name: "Vietnam",
        phonecode: "84"
    },
    {
        id: "239",
        shortname: "VG",
        name: "Virgin Islands (British)",
        phonecode: "1284"
    },
    {
        id: "240",
        shortname: "VI",
        name: "Virgin Islands (US)",
        phonecode: "1340"
    },
    {
        id: "241",
        shortname: "WF",
        name: "Wallis And Futuna Islands",
        phonecode: "681"
    },
    {
        id: "242",
        shortname: "EH",
        name: "Western Sahara",
        phonecode: "212"
    },
    {
        id: "243",
        shortname: "YE",
        name: "Yemen",
        phonecode: "967"
    },
    {
        id: "244",
        shortname: "YU",
        name: "Yugoslavia",
        phonecode: "38"
    },
    {
        id: "245",
        shortname: "ZM",
        name: "Zambia",
        phonecode: "260"
    },
    {
        id: "246",
        shortname: "ZW",
        name: "Zimbabwe",
        phonecode: "263"
    }
];